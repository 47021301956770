<template>
    <div class="content-wrapper">
        <PageHeader :screenName="pageTitle" :linkItems="linkItems"/>

        <ConfirmModal
            itemIdentifier="unidade"
            :modalShow="modal.showModal"
            :typeModal="modal.typeModal"
            @confirmed="cancelRegister"
            @canceled="closeModalConfirm"
        />

        <SucessoModal
            :modalShow="modalSuccess.showModal"
            :typeModal="modalSuccess.typeModal"
            :caseModal="modalSuccess.caseModal"
            @confirmed="redirectToList"
        />

        <ErrorModal
            :modalShow="modalError.showModal"
            :typeModal="modalError.typeModal"
            :caseModal="modalError.caseModal"
            @confirmed="redirectToList"
        />

        <main class="card p-2">
            <section class="form">
                <validation-observer ref="UnidadesRules">
                    <b-form :class="{'opacity-50': loadingData}">
                        <b-row><b-col lg="3" md="6" sm="12">
                                <validation-provider
                                    #default="{ errors }"
                                    name="departamentoRegional"
                                    rules="required"
                                >
                                    <b-form-group label="Departamento Regional*" label-for="regionalDepartment-input"
                                        :state="errors.length > 0 || form.errors.regionalDepartment ? false:null"
                                    >
                                        <v-select
                                            id="regionalDepartment-input"
                                            v-model="form.regionalDepartment"
                                            :options="regionalDepartments"
                                            label="descricao"
                                            placeholder="Selecione um departamento"
                                            @input="handleSelectRegionalDepartment"
                                            :disabled="loadingData || !userPermissions.edit"
                                            :clearable="false"
                                        >
                                            <span slot="no-options">Nenhuma opção selecionável.</span>
                                        </v-select>
                                        <small v-if="errors[0]" class="text-danger d-block">
                                            Este campo é de preenchimento obrigatório.
                                        </small>
                                        <small v-if="form.errors.regionalDepartment" class="text-danger">
                                            {{ form.errors.regionalDepartment }}
                                        </small>
                                    </b-form-group>
                                </validation-provider>
                            </b-col>

                            <b-col lg="4" md="6" sm="12" class="pr-0">
                                <b-form-group label="Nome da Unidade Operacional*" label-for="name-input">
                                    <validation-provider
                                        #default="{ errors }"
                                        name="name"
                                        rules="required"
                                    >
                                        <b-form-input
                                            :disabled="loadingData || !userPermissions.edit"
                                            :state="errors.length > 0 || form.errors.name ? false:null"
                                            id="name-input"
                                            v-model="form.name"
                                            placeholder="Ex: Sede DR/SC"
                                        />
                                        <small v-if="errors[0]" class="text-danger d-block">
                                            Este campo é de preenchimento obrigatório.
                                        </small>
                                        <small v-if="form.errors.name" class="text-danger">
                                            {{ form.errors.name }}
                                        </small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                            <b-col lg="1" md="6" sm="12" class="pr-0">
                            </b-col>
                            <b-col lg="4" md="6" sm="12">
                           
                        
                                <b-form-group label="Código para integração externa" label-for="codigoIntegracao-input"  >
                                    <b-form-input
                                        id="codigoIntegracao-input"
                                        :state="form.errors.codeExternalIntegration ? false:null"
                                        autocomplete="off"
                                        v-mask="('X'.repeat(7))"
                                        v-model="form.codeExternalIntegration"
                                        placeholder="Ex: XPTO-123"
                                        type="text"
                                        :disabled="loadingData || !userPermissions.edit"
                                    />
                                    <small v-if="form.errors.codeExternalIntegration" class="text-danger">
                                        {{ form.errors.codeExternalIntegration }}
                                    </small>
                                </b-form-group>
                          
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col lg="3" md="6" sm="12">
                                <validation-provider
                                    #default="{ errors }"
                                    name="cnpj"
                                    rules="required"
                                >
                                    <b-form-group label="CNPJ da Unidade Operacional*" label-for="cnpj-input">
                                        <b-form-input
                                            :disabled="loadingData || !userPermissions.edit"
                                            :state="errors.length > 0 || form.errors.cnpj ? false:null"
                                            id="cnpj-input"
                                            autocomplete="off"
                                            v-mask="('XX.XXX.XXX/XXXX-XX')"
                                            v-model="form.cnpj"
                                            placeholder="00.000.000/0000-00"
                                        />
                                        <small v-if="errors[0]" class="text-danger d-block">
                                            Este campo é de preenchimento obrigatório.
                                        </small>
                                        <small v-if="form.errors.cnpj" class="text-danger">
                                            {{ form.errors.cnpj }}
                                        </small>
                                    </b-form-group>
                                </validation-provider>
                            </b-col>

                            <b-col lg="3" md="6" sm="12" class="pr-0">
                                <validation-provider
                                    #default="{ errors }"
                                    name="Cidade"
                                    rules="required"
                                >
                                    <b-form-group label="Cidade (local físico)*" label-for="city-input"
                                        :state="errors.length > 0 || form.errors.city ? false:null"
                                    >
                                        <v-select
                                            :disabled="alowedProfileEdit()"
                                            id="city-input"
                                            v-model="form.city"
                                            :reduce="(option => option.id_cidade)"
                                            :options="cities"
                                            label="nome"
                                            placeholder="Selecione uma cidade"
                                        >
                                            <span slot="no-options">Nenhuma opção selecionável.</span>
                                        </v-select>
                                        <small v-if="errors[0]" class="text-danger d-block">
                                            Este campo é de preenchimento obrigatório.
                                        </small>
                                        <small v-if="form.errors.city" class="text-danger">
                                            {{ form.errors.city }}
                                        </small>
                                    </b-form-group>
                                </validation-provider>
                            </b-col>
                            <b-col lg="2" md="6" sm="12" class="pr-0">
                            </b-col>
                            <b-col lg="4" md="6" sm="12">
                                <b-form-group label="Código SMD da Unidade Operacional" label-for="codigoSmd-input">
                                    <b-form-input
                                        :state=" form.errors.smdCodeUnit ? false:null"
                                        :disabled="loadingData || !userPermissions.edit"
                                        id="codigoSmd-input"
                                        autocomplete="off"
                                        v-mask="('X'.repeat(7))"
                                        v-model="form.smdCodeUnit"
                                        placeholder="Ex: XPTO-123"
                                        type="text"
                                    />
                                    <small v-if="form.errors.smdCodeUnit" class="text-danger">
                                        {{ form.errors.smdCodeUnit }}
                                    </small>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col lg="3" md="6" sm="12" v-if="useRegion">
                                <validation-provider
                                    #default="{ errors }"
                                    name="region"
                                    rules="required"
                                >
                                    <b-form-group label="Região*" label-for="region-input"
                                        :state="errors.length > 0 || form.errors.region ? false:null"
                                    >
                                        <v-select
                                            :disabled="loadingData || !userPermissions.edit"
                                            id="region-input"
                                            v-model="form.region"
                                            :reduce="(option => option.id_regiao)"
                                            :options="region"
                                            label="descricao"
                                            placeholder="Selecione uma região"
                                        >
                                            <span slot="no-options">Nenhuma opção selecionável.</span>
                                        </v-select>
                                        <small v-if="errors[0]" class="text-danger d-block">
                                            Este campo é de preenchimento obrigatório.
                                        </small>
                                        <small v-if="form.errors.region" class="text-danger">
                                            {{ form.errors.region }}
                                        </small>
                                    </b-form-group>
                                </validation-provider>
                            </b-col>
                        </b-row>

                        <section v-show="typeof idDepartamento == 'number'">
                            <ResponsibleField 
                                v-model="form.regionalDepartment"
                                labelName="Responsável por esta Unidade Operacional"
                                :error="form.errors.erroResponsible"
                                :preSelected="form.responsible"
                                @selected="fillResponsible"
                                :regionalDepartment="form.regionalDepartment"
                                :selectDisabled="!userPermissions.editResponsible"
                                :idDepartamento="idDepartamento"
                            />
                        </section>
                    </b-form>
                </validation-observer>
                <LinkCitiesUnits 
                    :cities="cities"
                    :ciityPhysicalLocation="form.city"
                    :citiesAlreadyLinked="citiesAlreadyLinked"
                    @linkedCities="(cities) => citiesLinked = cities"
                     :noCityLinked="noCityLinked"
                />
            </section>
             <section class="buttons mt-3">
                <b-button id="save-unidade"
                    class="mr-2"
                    variant="custom-blue"
                    @click.prevent="validationForm"
                    :disabled="saving || loadingData"
                >
                    <feather-icon icon="CheckIcon" class="mr-50"/>
                    <span class="align-middle">Salvar alterações</span>
                    <span v-if="saving" class="spinner-border spinner-border-sm ml-1" role="status" aria-hidden="true"></span>
                </b-button>
                <b-button id="cancel-register-departamento"
                    :disabled="saving"
                    variant="outline-danger"
                    @click.prevent="openModalConfirm"
                >
                    <feather-icon icon="XIcon" class="mr-50"/>
                    <span class="align-middle">Cancelar</span>
                </b-button>
            </section>
        </main>
    </div>
</template>

<script>
import { BRow, BCol, BForm, BFormGroup, BFormInput, BButton } from 'bootstrap-vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import vSelect from 'vue-select';
import PageHeader from '@/views/components/custom/page-header/PageHeader.vue';
import LinkCitiesUnits from '@/views/components/custom/LinkCitiesUnits/LinkCitiesUnits.vue';
import ConfirmModal from '@/views/components/custom/modals/ConfirmModal.vue';
import SucessoModal from '@/views/components/custom/modals/SucessoCriacaoEdicaoModal.vue';
import ErrorModal from '@/views/components/custom/modals/ErroIdInexistenteModal.vue'
import TimedAlert from '@/views/components/custom/timed-alert/TimedAlert.vue';
import ResponsibleField from '@/views/components/custom/field-select-responsible-departamento/ResponsibleField.vue';
import handleErrorsService from './Services/handleErros';
import {getUserData} from "@/auth/utils";
import { getIdUnidade, getAcronymUnidade } from '@core/utils/store/getStore';
import { setIdUnidade, setAcronymUnidade, setListFilter } from "@core/utils/store/setStore";
import {actions, subjects} from "@/libs/acl/rules";
export default {
    title: 'Editar unidade',

    components: {
        PageHeader, BForm, BFormGroup, BFormInput, BButton,
        BCol, BRow, ValidationProvider, ValidationObserver,
        vSelect, ConfirmModal, SucessoModal, TimedAlert, 
        ResponsibleField, ErrorModal, LinkCitiesUnits
    },

    data() {
        return {
            userData: getUserData(),
            linkItems: [
                {
                    name: 'Unidades Operacionais',
                    routeName: 'unidade-list'
                },
                {
                    name: 'Editar',
                    active: true
                }
            ],
            pageTitle: 'Editar unidade',
            required,
            errorExist: false,
            modal: {
                showModal: false,
                typeModal: 'danger'
            },
            modalSuccess: {
                showModal: false,
                typeModal: 'success',
                caseModal: 'edicao'
            },
            modalError: {
                showModal: false,
                typeModal: 'error',
                caseModal: 'idInexistente'
            },
            savedAlert: false,
            saving: false,
            loadingData: true,
            form: {
                name: null,
                smdCodeUnit: null,
                codeExternalIntegration: null,
                cnpj: null,
                city: null,
                region: null,
                regionalDepartment: null,
                responsible: null,
                email: null,
                department: null,
                errors: {
                    name: null,
                    cnpj: null,
                    smdCodeUnit:null,
                    codeExternalIntegration: null,
                    city: null,
                    regionalDepartment: null,
                    region: null,
                    responsible: null
                }
            },
            useRegion: false,
            region: [],
            cities: [],
            citiesLinked: [],
            regionalDepartments: [],
            citiesAlreadyLinked: [],
            userPermissions: {
                edit: false,
                editResponsible: false
            },
            idDepartamento:null,
            noCityLinked: false
        }
    },

    mounted() {
        const hasModalError = this.$helpers.verifyIfCanOpenModalError(getIdUnidade(), this.$route.params.id_unidade);
        const hasIdEntityRouteParam = this.$helpers.verifyIfHasIdEntityRouteParam(getIdUnidade(), this.$route.params.id_unidade);
        
        if(hasModalError)
            return this.openModalError();
        
        if(hasIdEntityRouteParam) {
            setIdUnidade(this.$route.params.id_unidade); 
            setAcronymUnidade(this.$route.params.acronym);
        }
        
        this.linkItems[1].name = 'Editar: ' + getAcronymUnidade();
        this.pageTitle = 'Editar ' + getAcronymUnidade();
        this.loadRegionalDepartments();
        this.loadDataUnidade();
        this.setUserPermissions(this.$store.state.sessions.userData.ability);
    },

    methods: {
        validationForm() { 
            this.noCityLinked = false;
            this.$refs.UnidadesRules.validate().then(success => {
                if (success) {
                    if(this.citiesLinked.length == 0) {
                        this.noCityLinked = true;
                        this.saving = false;
                        this.savingAndNew = false;

                        return;
                    }
                    this.saving = true;
                    this.editUnidade();
                }
            })
        },

        alowedProfileEdit() {
            const profiles = this.userData.perfis.map(profile => profile.id_perfil);

            return !(profiles.includes(this.$perfil.ADMIN_DN) || profiles.includes(this.$perfil.SAUDE_DN));
        },

        async editUnidade() {
            const parameters = {
                'descricao': this.form.name,
                'id_regiao': this.form.region,
                'id_cidade': this.form.city,
                'id_departamento': this.form.regionalDepartment.id_departamento,
                'codigo_smd': this.form.smdCodeUnit,
                'codigo_smd_integracao' : this.form.codeExternalIntegration,
                'cnpj': this.form.cnpj.replaceAll(/[^0-9]/g, ''),
                'cidades_vinculadas': this.citiesLinked.map(city => city.id_cidade)
            }
     
            let response = this.$http.put(this.$api.unidadeParameter(getIdUnidade()), parameters).then(() => {
                
                this.saving = false;
                this.openModalSucesso();
                return true
            }).catch(({ response: { data } }) => {
                this.errorExist = true
                handleErrorsService.handleErrosUnidade(data, this.form.errors);
                this.saving = false;
                return false
                
            })
            if(!await response) return;

            this.$http.patch(this.$api.unidadeResponsavel(getIdUnidade()), {
                'id_usuario_responsavel': this.form.responsible
            }).then(() => {
        
                this.saving = false;
                this.openModalSucesso();
            }).catch(({response: { data }}) => {
                handleErrorsService.handleErrosUnidade(data, this.form.errors);

                this.saving = false;
            })
        },

        loadDataUnidade() {
            this.$http.get(this.$api.unidadeParameter(getIdUnidade())).then(({ data }) => {
                this.form.name = data.descricao;
                this.linkItems[1].name = 'Editar: ' + data.descricao;
                this.pageTitle = 'Editar ' + data.descricao;
                this.form.smdCodeUnit = data.codigo_smd;
                this.form.codeExternalIntegration = data.codigo_smd_integracao;
                this.form.cnpj = data.cnpj;
                this.form.regionalDepartment = data.regiao.departamento;
                this.form.city = data.id_cidade;
                this.form.region = data.id_regiao;
                this.form.department = data.regiao.id_departamento;
             
                this.idDepartamento = this.form.regionalDepartment.id_departamento

                this.citiesAlreadyLinked = data.cidade;

                setListFilter({route: this.$router.currentRoute.name, departamento:{id_departamento: data.regiao.id_departamento, descricao: data.regiao.descricao}})
                if(data.usuario_contato[0]) {
                    this.form.responsible = data.usuario_contato[0].id_usuario;
                    this.form.email = data.usuario_contato[0].email;
                }

                if(this.form.regionalDepartment.divisao_por_regiao){
                    this.useRegion = true;
                    this.loadRegion();
                }else{
                    this.useRegion = false;
                }
                this.loadCidades();

                this.loadingData = false;
            }).catch(() => {
                this.redirectToList();
            });
        },

        cleanForm() {
            this.form.name = null;
            this.form.smdCodeUnit = null;
            this.form.cnpj = null;
            this.form.city = null;
            this.form.region = null;
            this.form.responsible = null;

            this.form.errors.name = null;
            this.form.errors.smdCodeUnit = null;
            this.form.errors.codeExternalIntegration = null;
            this.form.errors.cnpj = null;
            this.form.errors.city = null;
            this.form.errors.region = null;
            this.form.errors.responsible = null;

            this.$refs.UnidadesRules.reset();
        },

        loadRegion() {
            this.$http.get(this.$api.regiao(), {
                params: {
                    ativo: true,
                    id_departamento: this.form.regionalDepartment.id_departamento
                }
            }).then(({ data }) => {
               this.region = data;
            });
        },

        loadCidades() {
            this.$http.get(this.$api.getCidadesDoEstado(this.form.regionalDepartment.uf)).then(({ data }) => {
                this.cities = data.cidades;
            });
        },

        loadRegionalDepartments() {
            this.$http.get(this.$api.departamento(), {
                params: {
                    ativo: true
                }
            }).then(({data}) => {
                this.regionalDepartments = data;
            });
        },

        handleSelectRegionalDepartment(){
            if(this.form.regionalDepartment.divisao_por_regiao){
                this.useRegion = true;
                this.loadRegion();
            }else{
                this.useRegion = false;
            }
            this.form.city = null;
            this.form.region = null;
            this.form.responsible = null;
            this.loadCidades();
        },

        redirectToList() {
            this.$router.push({ name: 'unidade-list' });
        },

        closeModalConfirm() {
            this.modal.showModal = false;
        },

        openModalSucesso() {
            this.modalSuccess.showModal = true;
        },

        openModalConfirm() {
            this.modal.showModal = true;
        },

        openModalError() {
            this.modalError.showModal = true;
        },

        cancelRegister() {
            this.$router.push({ name: 'unidade-list' });
        },

        fillResponsible(event) {
            this.form.responsible = event;
        },

        setUserPermissions(permissions) {
            const editResponsiblePermissions = permissions.filter(state => {
                return (
                    (state.action === actions.RESPONSAVEL_ATUALIZAR && state.subject === subjects.UNIDADE_DR) ||
                    (state.action === actions.RESPONSAVEL_ATUALIZAR && state.subject === subjects.UNIDADE_DN)
                );
            });

            const editPermissions = permissions.filter(state => {
                return state.action === actions.ATUALIZAR && state.subject === subjects.UNIDADE
            })

            this.userPermissions = {
                edit: editPermissions.length > 0,
                editResponsible: editResponsiblePermissions.length > 0
            }
        }
    }
}
</script>

<style>
    [dir] .vs--disabled .vs__dropdown-toggle,
    [dir] .vs--disabled .vs__clear,
    [dir] .vs--disabled .vs__search,
    [dir] .vs--disabled .vs__selected,
    [dir] .vs--disabled .vs__open-indicator{
        background-color: #efefef;
    }
</style>
